import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServiceUserLabelComponent } from './service-user-label.component';
import { ServiceUserLabelService } from './service-user-label.service';
import { FeatureAllowModule } from '../directives/features-allow.module';
import { MaterialModule } from '../../../../framework/material/material.module';

@NgModule({
  declarations: [ServiceUserLabelComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FeatureAllowModule,
    
  ],
  providers: [ServiceUserLabelService],
  entryComponents: [ServiceUserLabelComponent],
  exports: [ServiceUserLabelComponent]
})
export class ServiceUserLabelModule{ }
