import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { CanDeactivateComponent } from './can-deactivate.interface';

@Injectable({
    providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
    
    constructor() { }
    
    canDeactivate(component: CanDeactivateComponent): Observable<boolean> | Promise<boolean> | boolean  {
        if (!localStorage.getItem('lastActivity')) {
            return true;
        }
        return component?.canDeactivate ? component.canDeactivate() : true;
    }
}
